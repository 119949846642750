:root {
  --primary: #64ffda;
  --secondary: #2d2d2d;
  --background: #0a192f;
  --text: #e0e0e0;
  --hover: rgba(100, 255, 218, 0.1);
  --transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

[data-theme="dark"] {
  --primary: #64ffda;
  --secondary: #2d2d2d;
  --background: #0a192f;
  --text: #e0e0e0;
  --hover: rgba(100, 255, 218, 0.1);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  color: #e0e0e0;
  background-color: var(--background);
  transition: var(--transition);
  overflow-x: hidden;
}

.custom-cursor {
  width: 20px;
  height: 20px;
  border: 2px solid var(--primary);
  border-radius: 50%;
  position: fixed;
  pointer-events: none;
  z-index: 9999;
  transition: transform 0.2s ease;
}

.custom-cursor.hover {
  transform: scale(1.5);
  background-color: var(--hover);
}

/* All Things Text */
p {
  font-family: Karla;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 1rem;
  transition: var(--transition);
}

h1 {
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  color: #64ffda;
  background: linear-gradient(45deg, var(--primary), var(--secondary));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: var(--transition);
}

h2 {
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  color: #64ffda;
  background: linear-gradient(45deg, var(--primary), var(--secondary));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: var(--transition);
}

h3 {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  color: #64ffda;
  background: linear-gradient(45deg, var(--primary), var(--secondary));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: var(--transition);
}

h4 {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  color: #64ffda;
  background: linear-gradient(45deg, var(--primary), var(--secondary));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: var(--transition);
}

h5 {
  font-family: Karla;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 1rem;
  transition: var(--transition);
}

h6{
  font-family: Karla;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 1rem;
  transition: var(--transition);
}

.otherlink{
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  background: linear-gradient(45deg, var(--primary), var(--secondary));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: var(--transition);
}

.nav-font{
  font-size: 24px;
}

b{
  color: #64ffda;
}

/* .link {
  text-decoration: none;
  font-weight: bold;
  background-image: linear-gradient(var(--primary), var(--primary));
  color: var(--primary);
  background-repeat: no-repeat;
  background-position: 0% 100%;
  background-size: 100% 2px;
  transition: background-size .6s, background-position .25s ease-in .25s;
}

.link:hover {
  text-decoration: none;
  color: #0a192f;
  background-size: 100% 100%;
  background-position: 0% 0%;
  transition: color 0.6s, background-position .25s, background-size .25s ease-in .25s;
} */

/* Remove default paddings */
.row {
  padding: 0;
  margin: 0;
}

.col{
  padding: 0;
  margin: 0;
}

.container-fluid{
  padding: 0;
  margin: 0;
}

.nopad{
  padding: 0;
  margin: 0;
}

/* Spacers */
.spacerL{
  height: 160px;
}

.spacerM{
  height: 100px;
}

.spacerS{
  height: 40px;
}

/* styling stuff */

.titlewidth{
  width:80%
}

.card{
  border: none;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: var(--transition);
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 30px -15px rgba(100, 255, 218, 0.2);
}

.left{
  padding-right:32px;
}

.right{
  padding-left:32px;
}

.workcardtop{
  width:100%;
  height:18px;
  border-radius: 12px 12px 0px 0px;
}

#piccontainer {
  position: relative;
  width: 100%;
  padding-top: 100%;
}

#pic {
  position: absolute;
  width: 100%;
  height: 100%;
}

.hovercard{
  border-radius: 12px;
  transition: 0.5s;
}

.hovercard:hover{
  transition: 0.5s;
  background: rgba(100, 255, 218, 0.05);
}

.aa{
  color: inherit;
}

.aa:link {
  text-decoration: none;
  color: inherit;
}

.aa:visited {
  text-decoration: none;
  color: inherit;
}

.aa:hover {
  text-decoration: none;
  color: inherit;
}

.aa:active {
  text-decoration: none;
  color: inherit;
}

/* used for testing */
.my-container{
  border: 3px solid green;
}

.my-row{
  border: 3px solid red;
}

.my-col{
  border: 3px dotted blue;
}

.bpad64{
  padding-bottom: 64px;
}

.bound{
  max-width:1116px; 
}

.smallBound{
  max-width:530px;
}

.greybackground{
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.05);
}

.first{
  margin-left: 0px;
}

.last{
  margin-right: 0px;
}

.navbar {
  margin-bottom: 0 
}

.custom-toggler.navbar-toggler {
  border-color:  var(--primary);
}

.custom-toggler.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(101,31,255,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.nav-top{
  margin-top: 48px;
}

.catch-eye{
  text-align:center;
  padding-bottom:24px;
}

.mainPill{
  background-color: rgba(100, 255, 218, 0.1);
  border: 1px solid #64ffda;
  color: #64ffda;
  padding: 6px 12px;
  text-align: center;
  display: inline-block;
  margin: 4px 3px;
  border-radius: 8px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  transition: all 0.3s ease;
}

.mainPill:hover {
  background-color: rgba(100, 255, 218, 0.2);
}

.secondaryPill{
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #e0e0e0;
  padding: 6px 12px;
  text-align: center;
  display: inline-block;
  margin: 4px 3px;
  border-radius: 8px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  transition: all 0.3s ease;
}

.secondaryPill:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.mainPillsm{
  background-color: rgba(100, 255, 218, 0.1);
  border: 1px solid #64ffda;
  color: #64ffda;
  padding: 6px 12px;
  text-align: center;
  display: inline-block;
  margin: 4px 3px;
  border-radius: 8px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
}

.secondaryPillsm{
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #e0e0e0;
  padding: 6px 12px;
  text-align: center;
  display: inline-block;
  margin: 4px 3px;
  border-radius: 8px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
}

.max531{
  max-height: 531px;
}

.h384{
  height: 384px;
}

.mobile{
  width:0px;
  height:0px;
}

.notmobile{
  width:auto;
  height:auto;
}

.monly{
  display:none;
}

.monlync{
  display:none;
}

@media (min-width: 560px) and (max-width: 780px) {
  .test{
    display:none;
  }
}

@media (min-width: 0px) and (max-width: 1090px) {

    /* Spacers */
  .spacerL{
    height: 100px;
  }

  .spacerM{
    height: 50px;
  }

  .spacerS{
    height: 50px;
  }

  h6{
    font-family: Karla;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
  }

  .nav-font{
    font-size: 18px;
  }

  .catch-eye{
    font-size: 16px;
    line-height: 20px;
    padding-bottom:12px;
  }

  p {
    font-family: Karla;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
  }
  
  h1 {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
  }
  
  h2 {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
  }
  
  h3 {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
  }
  
  h4 {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
  }

  .otherlink{
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
  }

  h5 {
    font-family: Karla;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
  }

  .mnone{
    display:none;
  }

  .monly{
    display:block;
    text-align: center;
  }

  .monlync{
    display:block;
  }

  .mobwidth{
    width:90%;
  }

  .mobile{
    width:auto;
    height:auto;
  }
  
  .notmobile{
    width:0px;
    height:0px;
  }

  .left{
    padding-right:0px;
  }
  
  .right{
    padding-left:0px;
  }
  

  .max531{
    max-height: auto;
  }

  .h384{
    height: auto;
  }

  .titlewidth{
    width:100%
  }

  .mainPillsm{
    background-color: rgba(100, 255, 218, 0.1);
    border: 1px solid #64ffda;
    color: #64ffda;
    padding: 4px 8px;
    text-align: center;
    display: inline-block;
    margin: 4px 3px;
    border-radius: 8px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
  }
  
  .secondaryPillsm{
    background-color: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: #e0e0e0;
    padding: 4px 8px;
    text-align: center;
    display: inline-block;
    margin: 4px 3px;
    border-radius: 8px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
  }

  .mainPill{
    background-color: rgba(100, 255, 218, 0.1);
    border: 1px solid #64ffda;
    color: #64ffda;
    padding: 4px 8px;
    text-align: center;
    display: inline-block;
    margin: 4px 3px;
    border-radius: 8px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
  }
  
  .secondaryPill{
    background-color: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: #e0e0e0;
    padding: 4px 8px;
    text-align: center;
    display: inline-block;
    margin: 4px 3px;
    border-radius: 8px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
  }

  .mobile-nav-first{
    margin-left:1.5rem;
  }

  .m-nav-spacer{
    margin-left: 28px;
    margin-top: 48px;
  }

  .nav-top{
  }

  
  .bpad64{
  }

}

/* Navbar Styles */
.navbar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 1rem 0;
  background: rgba(10, 25, 47, 0.85);
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
}

.navbar-wrapper.scrolled {
  padding: 0.5rem 0;
  box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.3);
}

.navbar {
  padding: 0 2rem;
}

.nav-item {
  margin: 0 1rem;
}

.nav-link {
  position: relative;
  padding: 0.5rem 0;
  color: var(--text) !important;
  font-family: 'Karla', sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  transition: all 0.3s ease;
}

.nav-number {
  color: #64ffda;
  font-size: 14px;
  margin-right: 0.5rem;
  font-family: 'Fira Code', monospace;
}

.nav-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #64ffda;
  transition: width 0.3s ease;
}

.nav-link:hover::after,
.nav-link.active::after {
  width: 100%;
}

.nav-link:hover,
.nav-link.active {
  color: #64ffda !important;
}

.navbar-toggler {
  border: none;
  padding: 0;
  width: 30px;
  height: 30px;
  position: relative;
  background: transparent;
}

.toggler-icon {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #64ffda;
  margin: 6px 0;
  transition: all 0.3s ease;
  transform-origin: center;
}

.navbar-toggler.open .toggler-icon:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.navbar-toggler.open .toggler-icon:nth-child(2) {
  opacity: 0;
}

.navbar-toggler.open .toggler-icon:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

.nav-social-link {
  color: var(--text);
  margin-left: 1.5rem;
  transition: all 0.3s ease;
}

.nav-social-link:hover {
  color: #64ffda;
  transform: translateY(-2px);
}

@media (max-width: 991px) {
  .navbar-collapse {
    background: rgba(10, 25, 47, 0.95);
    backdrop-filter: blur(10px);
    padding: 1rem;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.3);
  }

  .nav-item {
    margin: 0.5rem 0;
  }

  .navbar-nav.ml-auto {
    margin-top: 1rem;
    justify-content: center;
    display: flex;
  }

  .nav-social-link {
    margin: 0 1rem;
  }
}

/* About Page Styles */
.about-page {
  min-height: 100vh;
  background-color: var(--background);
  color: var(--text);
}

.about-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 120px 24px 60px;
}

/* Hero Section */
.hero-section {
  margin-bottom: 80px;
}

.hero-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 48px;
}

.hero-text {
  flex: 1;
}

.gradient-text {
  font-size: 4rem;
  font-weight: 700;
  background: linear-gradient(120deg, #64ffda, #0a192f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 24px;
}

.hero-subtitle {
  font-size: 1.5rem;
  color: var(--text);
  opacity: 0.9;
}

.hero-image {
  position: relative;
  flex: 1;
  max-width: 400px;
}

.profile-image {
  width: 100%;
  height: auto;
  border-radius: 12px;
  position: relative;
  z-index: 2;
  transition: transform 0.3s ease;
}

.profile-image:hover {
  transform: translateY(-5px);
}

.image-backdrop {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 100%;
  height: 100%;
  background: #64ffda;
  border-radius: 12px;
  opacity: 0.2;
  z-index: 1;
}

/* About Section */
.about-section {
  margin-bottom: 80px;
  padding: 40px;
  background: rgba(100, 255, 218, 0.05);
  border-radius: 16px;
  backdrop-filter: blur(10px);
}

.about-text h2 {
  font-size: 2.5rem;
  margin-bottom: 24px;
  color: #64ffda;
}

.about-text p {
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 20px;
}

.highlight {
  color: #64ffda;
  font-weight: 500;
}

.link-highlight {
  color: #64ffda;
  text-decoration: none;
  position: relative;
  transition: all 0.3s ease;
}

.link-highlight::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: #64ffda;
  transition: width 0.3s ease;
}

.link-highlight:hover::after {
  width: 100%;
}

/* Skills Section */
.skills-section {
  margin-bottom: 80px;
}

.skills-section h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  text-align: center;
  color: #64ffda;
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 24px;
}

.skill-card {
  background: rgba(100, 255, 218, 0.05);
  padding: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 16px;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.skill-card:hover {
  transform: translateY(-5px);
  background: rgba(100, 255, 218, 0.1);
}

.skill-icon {
  font-size: 2rem;
}

.skill-name {
  font-size: 1.1rem;
  font-weight: 500;
}

/* Interests Section */
.interests-section {
  margin-bottom: 80px;
}

.interests-section h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  text-align: center;
  color: #64ffda;
}

.interests-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 24px;
}

.interest-card {
  background: rgba(100, 255, 218, 0.05);
  padding: 32px;
  border-radius: 12px;
  transition: transform 0.3s ease;
}

.interest-card:hover {
  transform: translateY(-5px);
  background: rgba(100, 255, 218, 0.1);
}

.interest-card h3 {
  color: #64ffda;
  margin-bottom: 16px;
  font-size: 1.3rem;
}

.interest-card p {
  font-size: 1rem;
  line-height: 1.6;
  opacity: 0.9;
}

/* Connect Section */
.connect-section {
  text-align: center;
  padding: 60px 0;
}

.connect-section h2 {
  font-size: 2.5rem;
  margin-bottom: 24px;
  color: #64ffda;
}

.connect-content {
  max-width: 600px;
  margin: 0 auto;
}

.connect-content p {
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 32px;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 24px;
}

.social-link {
  display: inline-block;
  padding: 12px 24px;
  background: rgba(100, 255, 218, 0.1);
  color: #64ffda;
  text-decoration: none;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.social-link:hover {
  background: rgba(100, 255, 218, 0.2);
  transform: translateY(-3px);
}

/* Responsive Design */
@media (max-width: 768px) {
  .about-container {
    padding: 100px 16px 40px;
  }

  .hero-content {
    flex-direction: column-reverse;
    text-align: center;
  }

  .gradient-text {
    font-size: 3rem;
  }

  .hero-subtitle {
    font-size: 1.2rem;
  }

  .hero-image {
    margin: 0 auto;
  }

  .about-section {
    padding: 24px;
  }

  .about-text h2,
  .skills-section h2,
  .interests-section h2,
  .connect-section h2 {
    font-size: 2rem;
  }

  .skills-grid,
  .interests-grid {
    grid-template-columns: 1fr;
  }
}

/* Projects Page Styles */
.projects-page {
  min-height: 100vh;
  background-color: var(--background);
  color: var(--text);
}

.projects-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 120px 24px 60px;
}

/* Hero Section */
.projects-hero {
  text-align: center;
  margin-bottom: 60px;
}

.projects-hero .gradient-text {
  font-size: 4rem;
  margin-bottom: 16px;
}

.projects-subtitle {
  font-size: 1.2rem;
  opacity: 0.9;
  max-width: 600px;
  margin: 0 auto;
}

/* Filter Section */
.projects-filter {
  margin-bottom: 48px;
}

.filter-buttons {
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
}

.filter-btn {
  padding: 8px 24px;
  border: none;
  background: rgba(100, 255, 218, 0.1);
  color: var(--text);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Karla', sans-serif;
  font-size: 0.9rem;
  letter-spacing: 1px;
}

.filter-btn:hover,
.filter-btn.active {
  background: rgba(100, 255, 218, 0.2);
  color: #64ffda;
  transform: translateY(-2px);
}

/* Projects Grid */
.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 32px;
  margin-bottom: 80px;
}

.project-card {
  background: rgba(100, 255, 218, 0.05);
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.project-card:hover {
  transform: translateY(-8px);
  background: rgba(100, 255, 218, 0.08);
}

.project-image {
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  overflow: hidden;
}

.project-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.project-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(10, 25, 47, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease;
}

.project-card:hover .project-overlay {
  opacity: 1;
}

.project-card:hover .project-image img {
  transform: scale(1.1);
}

.view-project {
  color: #64ffda;
  text-decoration: none;
  padding: 12px 24px;
  border: 2px solid #64ffda;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.view-project:hover {
  background: rgba(100, 255, 218, 0.1);
  transform: translateY(-2px);
}

.project-content {
  padding: 24px;
}

.project-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.project-header h3 {
  font-size: 1.5rem;
  color: #64ffda;
  margin: 0;
}

.project-category {
  font-size: 0.9rem;
  padding: 4px 12px;
  background: rgba(100, 255, 218, 0.1);
  border-radius: 20px;
  color: #64ffda;
}

.project-description {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 16px;
  opacity: 0.9;
}

.project-highlight {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.9rem;
  color: #64ffda;
  margin-bottom: 16px;
  padding: 8px 16px;
  background: rgba(100, 255, 218, 0.05);
  border-radius: 8px;
}

.highlight-icon {
  font-size: 1.2rem;
}

.project-tech {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tech-tag {
  font-size: 0.8rem;
  padding: 4px 12px;
  background: rgba(100, 255, 218, 0.1);
  border-radius: 20px;
  color: var(--text);
}

/* Connect Banner */
.connect-banner {
  text-align: center;
  padding: 80px 24px;
  background: rgba(100, 255, 218, 0.05);
  border-radius: 16px;
  margin-bottom: 60px;
}

.connect-banner h2 {
  font-size: 2.5rem;
  color: #64ffda;
  margin-bottom: 16px;
}

.connect-banner p {
  font-size: 1.1rem;
  opacity: 0.9;
  margin-bottom: 32px;
}

.banner-links {
  display: flex;
  justify-content: center;
  gap: 24px;
}

.banner-link {
  display: inline-block;
  padding: 12px 32px;
  background: rgba(100, 255, 218, 0.1);
  color: #64ffda;
  text-decoration: none;
  border-radius: 8px;
  transition: all 0.3s ease;
  font-size: 1rem;
}

.banner-link:hover {
  background: rgba(100, 255, 218, 0.2);
  transform: translateY(-3px);
}

/* Responsive Design */
@media (max-width: 768px) {
  .projects-container {
    padding: 100px 16px 40px;
  }

  .projects-hero .gradient-text {
    font-size: 3rem;
  }

  .projects-grid {
    grid-template-columns: 1fr;
  }

  .filter-buttons {
    gap: 8px;
  }

  .filter-btn {
    padding: 6px 16px;
    font-size: 0.8rem;
  }

  .connect-banner {
    padding: 40px 16px;
  }

  .connect-banner h2 {
    font-size: 2rem;
  }

  .banner-links {
    flex-direction: column;
    gap: 16px;
  }
}